import React from 'react';
import styled from 'styled-components';
import Vimeo from '@u-wave/react-vimeo';

import { VideoWidget } from 'infrastructure/gatsby/types/video-section';

const Section = styled.section`
`;

const VideoSection: React.FC<VideoWidget> = ({
  sectionId,
  videoUrl,
}) => {
  const renderVideo = (url: string): React.ReactNode => {
    if (!url) {
      return null;
    }

    const urlMatch = url.match(/vimeo\.com\/(\d+)$/i);

    if (Array.isArray(urlMatch) && urlMatch.length > 0) {
      return (
        <Vimeo
          id={sectionId}
          video={urlMatch[1]}
          width="100%"
          responsive
          showPortrait={false}
          showTitle={false}
          controls={false}
          showByline={false}
        />
      );
    }

    return null;
  };

  return (
    <Section>
      {renderVideo(videoUrl)}
    </Section>
  );
};

export default VideoSection;
